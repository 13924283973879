import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container } from "../components/styled-components"

interface Props {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string
      },
      excerpt: string,
      html: string
    }
  }
}

export default ({ data }: Props) => {
  const staticPage = data.markdownRemark
  return (
    <Layout>
      <SEO title={staticPage.frontmatter.title} description={staticPage.excerpt}/>
      <Container>
        <h1>{staticPage.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: staticPage.html }}/>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
      }
    }
  }
`
